<template>
  <div class="vehicles">
    <button
      class="button field is-info is-light"
      @click="
        isCardModalActive = true
        isChangeOdometerCardModalActive = false
      "
    >
      <b-icon icon="plus"></b-icon>
      <span>Novo veículo</span>
    </button>
    <button
      class="button field is-info is-light z-10"
      style="margin-left: 10px"
      @click="
        isCardModalActive = false
        isChangeOdometerCardModalActive = true
      "
    >
      <span>Atualizar odometro</span>
    </button>
    <b-table
      :data="allVehicles"
      ref="table"
      paginated
      per-page="10"
      detailed
      detail-key="id"
      :striped="true"
      :debounce-search="1000"
      @details-open="
        (row) => $buefy.toast.open(`Visualizando os detalhes de ${row.plate}`)
      "
      :show-detail-icon="true"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column
        field="plate"
        label="Placa"
        :searchable="true"
        sortable
        v-slot="props"
      >
        <template v-if="showDetailIcon">{{ props.row.plate }}</template>
        <template v-else>
          <a @click="props.toggleDetails(props.row)">{{ props.row.plate }}</a>
        </template>
      </b-table-column>

      <b-table-column
        field="model"
        label="Modelo"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ props.row.vehicle_model.model }}</b-table-column
      >

      <b-table-column
        field="type"
        label="Tipo de veículo"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ props.row.vehicle_type.name }}</b-table-column
      >

      <b-table-column
        field="brand"
        label="Marca"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ props.row.vehicle_model.brand }}</b-table-column
      >

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <span>Odômetro: {{props.row.odometer}} KM</span>
                <br/>
                <br/>
                <strong>Manutenções</strong>
                <br />
                <span
                  v-for="(item, index) of props.row.maintenanceStatus"
                  :key="index"
                >
                  - {{ item.status }} (Agendamento:
                  {{
                    item.scheduling
                      ? item.scheduling
                      : 'Nenhum agendamento criado'
                  }})
                  <br />
                </span>
                <br />
                <strong>{{ props.row.vehicle_model.model }}</strong>
                <br />
                <strong>Valor de compra:</strong>
                <small
                  >R$
                  {{ parseFloat(props.row.purchase_price).toFixed(2) }}</small
                >
              </p>
            </div>
          </div>
        </article>
      </template>
      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-light"
          @click.prevent="
            onEdit(props.row)
            isCardModalActive = true
          "
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>
    </b-table>

    <b-modal
      v-model="isChangeOdometerCardModalActive"
      :width="640"
      scroll="keep"
    >
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Quilometragem *">
              <b-input
                type="number"
                required
                v-model="selectedOdometer"
              ></b-input>
            </b-field>
            <b-field label="Motorista">
              <b-select
                expanded
                placeholder="Selecione um veículo"
                required
                v-model="selectedVehicle"
              >
                <option
                  v-for="(item, index) in allVehicles"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.plate }}
                </option>
              </b-select>
            </b-field>
            <b-button
              type="button field is-info"
              @click="changeOdometer"
              class="mt-10"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Placa *">
              <b-input required v-model="model.plate"></b-input>
            </b-field>
            <b-field label="Ano *">
              <b-input type="number" required v-model="model.year"></b-input>
            </b-field>
            <b-field label="Renavam *">
              <b-input type="number" required v-model="model.renavam"></b-input>
            </b-field>
            <b-field label="Chassi *">
              <b-input type="text" required v-model="model.chassi"></b-input>
            </b-field>
            <b-field label="Total de passageiros *">
              <b-input
                type="number"
                required
                v-model="model.max_passengers"
              ></b-input>
            </b-field>
            <b-field label="Seguradora *">
              <b-select
                placeholder="Selecione a seguradora"
                required
                expanded
                v-model="model.insurer_id"
              >
                <option
                  v-for="(item, index) in allSuppliers"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.company_name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Apólice *">
              <b-input
                type="number"
                required
                v-model="model.insurance_policy"
              ></b-input>
            </b-field>
            <b-field label="Expiração da Apólice *">
              <b-datepicker
                ref="datepicker"
                expanded
                v-model="model.policy_expiration"
                placeholder="Selecione uma data"
              ></b-datepicker>
              <b-button
                @click="$refs.datepicker.toggle()"
                icon-left="calendar-today"
                type="is-primary"
              />
            </b-field>
            <b-field label="Expiração do Licenciamento *">
              <b-datepicker
                ref="datepicker"
                expanded
                v-model="model.licensing_expiration"
                placeholder="Selecione uma data"
              ></b-datepicker>
              <b-button
                @click="$refs.datepicker.toggle()"
                icon-left="calendar-today"
                type="is-primary"
              />
            </b-field>
            <b-field label="Locadora">
              <b-select
                placeholder="Selecione a locadora"
                required
                expanded
                v-model="model.rental_id"
              >
                <option
                  v-for="(item, index) in allSuppliers"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.company_name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Expiração do Contrato">
              <b-datepicker
                ref="datepicker"
                expanded
                v-model="model.rental_contract_expiration"
                placeholder="Selecione uma data"
              ></b-datepicker>
              <b-button
                @click="$refs.datepicker.toggle()"
                icon-left="calendar-today"
                type="is-primary"
              />
            </b-field>
            <b-field label="Data de Compra *">
              <b-datepicker
                ref="datepicker"
                expanded
                v-model="model.purchase_date"
                placeholder="Selecione uma data"
              ></b-datepicker>
              <b-button
                @click="$refs.datepicker.toggle()"
                icon-left="calendar-today"
                type="is-primary"
              />
            </b-field>
            <b-field label="Nota Fiscal de Compra *">
              <b-input
                type="number"
                required
                v-model="model.purchase_invoice_number"
              ></b-input>
            </b-field>
            <b-field label="Valor de Compra *">
              <b-input
                required
                v-money="moneyFormat"
                v-model="model.purchase_price"
              ></b-input>
            </b-field>
            <b-field label="Centro de Custo *">
              <b-select
                placeholder="Selecione o centro de custo"
                required
                expanded
                v-model="model.cost_center_id"
              >
                <option
                  v-for="(item, index) in allCostCenters"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Modelo *">
              <b-select
                placeholder="Selecione o modelo"
                required
                expanded
                v-model="model.vehicle_model_id"
              >
                <option
                  v-for="(item, index) in allVehicleModels"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.model }} - {{ item.brand }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Tipo *">
              <b-select
                placeholder="Selecione o tipo"
                required
                expanded
                v-model="model.vehicle_type_id"
              >
                <option
                  v-for="(item, index) in allVehicleTypes"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Cor *">
              <b-select
                placeholder="Selecione a cor"
                required
                expanded
                v-model="model.vehicle_color_id"
              >
                <option
                  v-for="(item, index) in allVehicleColors"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Combustível *">
              <b-select
                placeholder="Selecione o combustível"
                required
                expanded
                v-model="model.fuel_type"
              >
                <option
                  v-for="(item, index) in [
                    'Gasolina',
                    'Álcool',
                    'Flex',
                    'GNV',
                    'Diesel',
                  ]"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </b-select>
            </b-field>
            <b-field>
              <b-checkbox v-model="model.utility"> Utilitário </b-checkbox>
            </b-field>
            <b-button type="button field is-info" @click="save" class="mt-10"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/vehicles/store/service'
import Vehicle from '../models/vehicle'

export default {
  name: 'Vehicles',
  data() {
    return {
      model: Vehicle.model,
      isCardModalActive: false,
      isChangeOdometerCardModalActive: false,
      selectedOdometer: null,
      selectedVehicle: null,
      moneyFormat: {
        prefix: 'R$ ',
        sstatefix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('vehicles', ['allVehicles']),
    ...mapState('vehicleModels', ['allVehicleModels']),
    ...mapState('vehicleTypes', ['allVehicleTypes']),
    ...mapState('suppliers', ['allSuppliers']),
    ...mapState('vehicleColors', ['allVehicleColors']),
    ...mapState('costCenters', ['allCostCenters']),
  },
  methods: {
    ...mapActions('vehicles', ['getAllVehicles', 'saveVehicle']),
    ...mapActions('vehicleModels', ['getAllVehicleModels']),
    ...mapActions('vehicleTypes', ['getAllVehicleTypes']),
    ...mapActions('vehicleColors', ['getAllVehicleColors']),
    ...mapActions('suppliers', ['getAllSuppliers']),
    ...mapActions('costCenters', ['getAllCostCenters']),

    async init() {
      await this.getAllVehicles()
      await this.getAllVehicleModels()
      await this.getAllVehicleTypes()
      await this.getAllVehicleColors()
      await this.getAllSuppliers()
      await this.getAllCostCenters()

      console.log('vehicles ', this.allVehicles)
    },

    async save() {
      const vehicle = {
        ...this.model,
      }

      vehicle.policy_expiration = this.$moment(
        vehicle.policy_expiration
      ).format('YYYY-MM-DD')
      vehicle.licensing_expiration = this.$moment(
        vehicle.licensing_expiration
      ).format('YYYY-MM-DD')
      vehicle.rental_contract_expiration = this.$moment(
        vehicle.rental_contract_expiration
      ).format('YYYY-MM-DD')
      vehicle.purchase_date = this.$moment(vehicle.purchase_date).format(
        'YYYY-MM-DD'
      )

      vehicle.purchase_price = vehicle.purchase_price.replace('R$ ', '')
      vehicle.purchase_price = vehicle.purchase_price.replace(',', '')

      if (vehicle.chassi.toString().trim().length !== 17) {
        this.$error('O campo CHASSI precisa ter 17 caracteres')
        return
      }

      if (vehicle.renavam.toString().trim().length !== 11) {
        this.$error('O campo RENAVAM precisa ter 11 números')
        return
      }

      try {
        const save = vehicle.id
          ? await service.updateVehicle(vehicle)
          : await service.saveVehicle(vehicle)

        await this.$success('Veículo')
        location.reload(true)
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
    async changeOdometer() {
      try {
        if (!this.selectedOdometer || !this.selectedVehicle)
          return this.$error(
            'é necessário preencher a quilometragem e o veículo'
          )
        await service.updateOdometer({
          id: this.selectedVehicle,
          odometer: this.selectedOdometer,
        })
        await this.$success('Odometro')
        location.reload(true)
      } catch (error) {
        this.$error(error)
      }
    },
    async onDelete(item) {
      try {
        const result = await this.$ask('Você deseja remover o veículo?')

        if (result.isConfirmed) {
          await service.deleteVehicle(item.id)

          await this.$delete('Veículo')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
    onEdit(item) {
      item.policy_expiration = new Date(this.$moment(item.policy_expiration))
      item.licensing_expiration = new Date(
        this.$moment(item.licensing_expiration)
      )
      item.rental_contract_expiration = new Date(
        this.$moment(item.rental_contract_expiration)
      )
      item.purchase_date = new Date(this.$moment(item.purchase_date))

      item.purchase_price = parseFloat(item.purchase_price).toFixed(2)

      item.utility = item.utility ? true : false

      this.model = { ...item }
    },
  },
}
</script>

<style>
.vm--modal {
  overflow-y: scroll !important;
}
</style>
