module.exports = {
  model: {
    id: '',
    plate: null,
    vehicle_model_id: null,
    vehicle_type_id: null,
    renavam: null,
    color_id: null,
    chassi: null,
    fuel_type: null,
    year: new Date(),
    purchase_date: null,
    purchase_invoice_number: null,
    purchase_price: null,
    insurer_id: null,
    insurance_policy: null,
    policy_expiration: null,
    rental_id: null,
    rental_contract_expiration: null,
    cost_center_id: null,
    accessories: [],
  },
}
